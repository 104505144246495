<template>
  <div>
    <br/><br/>
    <v-form
      ref="form"
      lazy-validation
    >
      <v-row>
        <v-col cols="12">
          <br/>
          <p v-if="popup" @click="showModal('patch')">La pose d'un patch permet de recouvrir la partie abîmée
            de votre vêtement
            <v-icon color="black darken-2" dark small>
              mdi-information
            </v-icon>
          </p>

        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p>Réalisation d’un patch au forfait qui comprend <br/><br/>
            <ul class="ul_normal">
              <li>l'échange avec vous par téléphone, mail et photo pour séléctionner le patch.</li>
              <li>le patch / écusson.</li>
              <li>la main d'oeuvre.</li>
            </ul>
          </p>
          <br/>
          <v-text-field
            v-model="emplacement"
            dense
            outlined
            placeholder="Décrivez votre emplacement"
          ></v-text-field>
          <br/>
          <br/>
          <image-preview
            ref="image_preview"
            :is-bonus-eligible="bonus > 0"
            :value="photo"
            @image="image => photo = image"
          />
          <v-checkbox
            v-model="isPatch"
            class="myInputCeck"
            label="J'ai un patch et je le glisse dans le colis"
            @click="getPrice"
          ></v-checkbox>
          <v-checkbox
            v-if="this.flux.level2 == 'DOUDOUNE'"
            v-model="transparent"
            class="myInputCeck"
            label="Ma déchirure fait moins de 1 cm, je souhaite un patch transparent"
          ></v-checkbox>
        </v-col>
        <price :amount="price" :bonus="bonus" display-bottom-line/>
      </v-row>
      <bonus-image-confirm
        v-if="bonus > 0 && !photo"
        @skip-bonus="bonus = 0"
        @add-picture="$refs.image_preview.$refs.file.click()"
      />
      <confirm-flow
        v-else-if="!this.flux.indexEditProduit"
        @finalize="noarticle"
        @new-repair="onSelectNewRepair"
        @new-article="noarticle(false)"
      />
      <v-row v-else>
        <v-col v-if="error" cols="12">
          <div class="text-center" style="color:#FF0000">Vous devez sélectionner le nombre de trous par
            dimensions
          </div>
        </v-col>
        <v-col class="text-center" cols="12">
          <div class="btn btn-1 text-center btn-size" @click="noarticle">MODIFIER</div>
        </v-col>
      </v-row>
    </v-form>
    <span style="display:none">{{ flux.actionId }}</span>
  </div>
</template>
<script>
import empPrice from '../../assets/data/price/patch.json';
import ImagePreview from '../interface/ImagePreview.vue'

import {mapMutations, mapState} from 'vuex'
import Price from "@/components/shared/Price.vue";
import BonusImageConfirm from "@/components/shared/BonusImageConfirm.vue";
import ConfirmFlow from "@/components/shared/ConfirmFlow.vue";

let liste_reparations = []
const NOTE_WITH_TRANSPARENT = ' - Avec patch transparent'

export default {
  data() {
    return {
      price: 0,
      bonus: 0,
      has_patch: false,
      emplacement: null,
      isPatch: false,
      transparent: false,
      popup: false,
      photo: null,
      error: false,
      requireRules: [
        v => !!v || 'Ce champs est requis'
      ],
    }
  },
  computed: {
    ...mapState('cart-app', ['cards', 'products']),
    ...mapState('flux-app', ['flux']),
  },
  created() {
    if (this.flux.activeTab !== 'patch') {
      return null;
    }

    this.price = empPrice[0][this.flux.level2].prix
    if (this.flux.isDouble) {
      this.price = this.price * 1.2
    }
    this.price = Math.round(this.price * 100) / 100
    this.bonus = empPrice[0][this.flux.level2]?.bonus?.[this.flux.isDouble ? 'double' : 'non_double'] ?? 0

    if (this.flux.level2 && this.flux.verrou === false && this.flux.indexEditProduit !== null) {
      this.setVerrou(true)
      this.emplacement = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['emplacement']
      this.price = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['price']
      this.isPatch = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['isPatch']
      this.photo = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['photo']
    }

    if (empPrice[0][this.flux.level2].popup !== undefined) {
      this.popup = empPrice[0][this.flux.level2].popup
    }
  },
  updated() {
    //trou-accroc-dechirure
    if (this.flux.activeTab !== 'patch') {
      return null;
    }

    if (this.flux.level2 && this.flux.verrou === false && this.flux.indexEditProduit !== null) {
      this.setVerrou(true)
      this.emplacement = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['emplacement']
      this.price = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['price']
      this.isPatch = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['isPatch']
      this.photo = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['photo']

      if (this.emplacement?.includes(NOTE_WITH_TRANSPARENT)) {
        this.emplacement = this.emplacement.replace(NOTE_WITH_TRANSPARENT, '')
        this.transparent = true
      }
    }
  },
  methods: {
    ...mapMutations('flux-app', ['setConstat', 'setcptProduct', 'setcptReparation', 'setVerrou', 'setLevel1', 'setLevel2', 'setLevel3', 'setComponentName']),
    ...mapMutations('cart-app', ['addProduct', 'getTotalCart']),
    getImg(value) {
      this.photo = value
    },
    noarticle(showCart = true) {
      if (this.price <= 0) {
        this.error = true;
      } else if (this.$refs.form.validate()) {
        if (this.flux.indexEditProduit !== null) { //Mode Edit
          this.editProduct();
          this.setLevel1(null)
          this.setLevel2(null)
          this.setLevel3(null)
          this.setComponentName(null)
        } else {
          this.storeProduct()
          this.setcptProduct(this.flux.cptProduct + 1)
          liste_reparations = []
          this.setcptReparation(0)
        }

        this.$emit('evenement', 'scroll1')

        if (showCart) {
          this.setConstat('cart')
          this.$emit('showmodal', 'cart')
        }
      }
    },
    onSelectNewRepair() {
      this.storeProduct()
      this.$emit('evenement', 'scroll3')
    },
    editProduct() {
      liste_reparations = this.products[this.flux.indexEditProduit].reparations
      //On check l'historique des réparations
      let index = liste_reparations.findIndex((obj => obj.id == (this.flux.indexEditReparation + 1)));
      if (index > -1) {
        let reparation = {
          id: liste_reparations[index].id,
          type: 'Patch',
          componentName: 'trou-accroc-dechirure',
          activeTab: 'patch',
          emplacement: this.emplacement,
          qte: 1,
          price: this.price,
          bonus: this.photo ? this.bonus : 0,
          isPatch: this.isPatch,
          photo: this.photo
        }

        this.products[this.flux.indexEditProduit].reparations.splice(index, 1, reparation)
        let data = {
          idLevel1: this.products[this.flux.indexEditProduit].idLevel1,
          level1: this.products[this.flux.indexEditProduit].level1,
          level2: this.products[this.flux.indexEditProduit].level2,
          level3: this.products[this.flux.indexEditProduit].level3,
          isDouble: this.products[this.flux.indexEditProduit].isDouble,
          id: this.products[this.flux.indexEditProduit].id,
          name: this.products[this.flux.indexEditProduit].name,
          famille: this.products[this.flux.indexEditProduit].famille,
          reparations: liste_reparations
        }


        this.addProduct(data)
        this.getTotalCart(this.products)
        this.$emit('evenement', 'scroll3')

      }
    },
    getPrice() {
      this.price = empPrice[0][this.flux.level2].prix
      if (this.isPatch === true) {
        this.price = this.price + empPrice[0][this.flux.level2].remise
      } else {
        this.bonus = empPrice[0][this.flux.level2]?.bonus?.[this.flux.isDouble ? 'double' : 'non_double'] ?? 0
      }

      if (this.flux.isDouble) {
        this.price = this.price * 1.2
      }
      this.price = Math.round(this.price * 100) / 100
      this.error = false
    },
    storeProduct() {
      if (this.price <= 0) {
        this.error = true;
      } else if (this.$refs.form.validate()) {
        this.setcptReparation(this.flux.cptReparation + 1)

        if (this.flux.cptProduct < 1) {
          this.setcptProduct(this.flux.cptProduct + 1)
        }

        //On check l'historique des réparations
        liste_reparations = []
        let index = this.products.findIndex((obj => obj.id == this.flux.cptProduct));
        if (index > -1) {
          liste_reparations = this.products[index].reparations
        }

        liste_reparations.push({
          id: this.flux.cptReparation,
          type: 'Patch',
          componentName: 'trou-accroc-dechirure',
          activeTab: 'patch',
          emplacement: `${this.emplacement ?? ''}${this.transparent ? NOTE_WITH_TRANSPARENT : ''}`,
          qte: 1,
          price: this.price,
          bonus: this.photo ? this.bonus : 0,
          isPatch: this.isPatch,
          photo: this.photo
        })


        let data = {
          idLevel1: this.flux.idLevel1,
          level1: this.flux.level1,
          level2: this.flux.level2,
          level3: this.flux.level3,
          isDouble: this.flux.isDouble,
          id: this.flux.cptProduct,
          name: this.flux.level2,
          famille: this.flux.level1,
          reparations: liste_reparations
        }
        this.addProduct(data)
        this.setLevel3(null)
        this.setComponentName(null)
        this.getTotalCart(this.products)
      }
    },
    showModal(constat) {
      this.setConstat(constat)
      this.$emit('showmodal')
    },
  },
  components: {
    ConfirmFlow,
    BonusImageConfirm,
    ImagePreview,
    Price
  }
}
</script>
